<template>
  <div class="table-responsive">
    <table
      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
    >
      <thead>
        <tr class="text-left">
          <!-- <th style="min-width: 250px" class="pl-7">
          <span class="text-dark-75">course</span>
        </th> -->
          <th style="min-width: 100px">Ticket No</th>
          <th style="min-width: 120px">Submission Information</th>
          <th style="min-width: 120px">Lesson / Test</th>
          <th style="min-width: 120px">Submit Time</th>
          <th style="min-width: 100px">Marker</th>
          <th style="min-width: 100px">Status</th>
          <th style="min-width: 100px">Marking date</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(marking_ticket, i) in marking_tickets">
          <tr v-bind:key="i">
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >#{{ marking_ticket.id }}</span
              >
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >{{ marking_ticket.student_name }} (ID: #{{
                  marking_ticket.student_id
                }})</span
              >
              <span class="text-body-1 text-muted font-weight-bold d-block">
                Course: {{ marking_ticket.course_name.vi }}
              </span>
              <span
                class="text-body-1 text-dark-50 font-weight-bold d-block"
                v-if="marking_ticket.class_name"
              >
                Class: {{ marking_ticket.class_name }}
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >{{ marking_ticket.title }}</span
              >
              <span class="text-body-1 font-weight-bold">
                Skill: {{ marking_ticket.skill_name }}
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ marking_ticket.time }}
                <span
                  class="text-body-1 text-dark-50 font-weight-bold d-block"
                  v-if="marking_ticket.draft_number"
                >
                  Draft Number: {{ marking_ticket.draft_number }}
                </span>
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ marking_ticket.marker_name }}
              </span>
            </td>
            <td>
              <span
                v-if="marking_ticket.status === 1"
                class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
              >
                Unmarked
              </span>
              <span
                v-if="marking_ticket.status === 2"
                class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
              >
                Marking
              </span>
              <span
                v-if="marking_ticket.status === 3"
                class="label label-lg label-inline font-weight-bold py-4 label-light-success"
              >
                Marked
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ marking_ticket.marking_time }}
              </span>
            </td>
            <!-- <td class="pr-0 text-right pr-5"> -->
          </tr>
        </template>
        <template v-if="!marking_tickets.length">
          <tr>
            <td style="text-align: center" colspan="9">No data</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SubmitStatus",
  props: {
    marking_tickets: {
      type: Array,
      default: () => [],
    },
    data_pagination: {
      type: Object,
      default: function () {
        return {
          total: 0,
          current_page: 1,
          total_pages: 1,
        };
      },
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
